// src/components/ThreadView.js

import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { htmlToText } from 'html-to-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import ReplyForm from './ReplyForm';
import { useAxiosInstance } from '../axiosInstance'; // Importera den anpassade Axios-hooken
import { AuthContext } from '../AuthContext'; // Importera AuthContext om nödvändigt

// Styled Components
const ThreadContainer = styled.div`
  padding: 20px;
  background-color: white;
  flex-grow: 1;
  overflow-y: auto;
  max-height: calc(73vh - 50px);
`;

const MessageItem = styled.div`
  border-bottom: 1px solid #e0e0e0;
`;

const AccordionHeader = styled.div`
  cursor: pointer;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Sender = styled.div`
  font-weight: bold;
  color: #333;
`;

const DateText = styled.div`
  font-size: 14px;
  color: #888;
`;

const Subject = styled.h3`
  margin: 5px 0;
  color: #000;
`;

const AccordionContent = styled.div`
  padding: 10px 0;
`;

const MessageSnippet = styled.p`
  color: #555;
  font-size: 14px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MessageContent = styled.div`
  font-family: 'Arial', sans-serif;
  padding: 10px 0;
  font-size: 16px;
  color: #333;
  line-height: 1.5;
`;

const CollapsedQuote = styled.div`
  margin-top: 10px;
`;

const ShowQuoteButton = styled.button`
  background: none;
  border-radius: 8px;
  border: none;
  color: blue;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const HideQuoteButton = styled.button`
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const ReplyButton = styled.button`
  margin-top: 20px;
  padding: 10px 25px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: black;
  border: 1px solid #000000;
  border-radius: 13px;
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

// Component
const ThreadView = () => {
  const { threadId } = useParams();
  const navigate = useNavigate();
  const [thread, setThread] = useState(null);
  const [expandedMessages, setExpandedMessages] = useState({});
  const [isQuoteExpanded, setIsQuoteExpanded] = useState({});
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [error, setError] = useState(null);

  const axiosInstance = useAxiosInstance(); // Använd den anpassade Axios-hooken
  const { authToken } = useContext(AuthContext); // Om du behöver authToken direkt

  // Funktion för att hämta tråden
  const fetchThread = async () => {
    try {
      const response = await axiosInstance.get(`/api/email/threads/${threadId}`);
      setThread(response.data);

      // Efter att ha hämtat tråden, markera den som läst
      await axiosInstance.post(`/api/email/threads/${threadId}/markAsRead`);
    } catch (error) {
      console.error('Error fetching thread:', error);
      setError('Kunde inte hämta tråden. Försök igen senare.');
    }
  };

  useEffect(() => {
    if (threadId) {
      fetchThread();
    }
    // Lägg till axiosInstance som beroende om den kan förändras
  }, [threadId, axiosInstance]);

  if (!thread) return <p>Loading...</p>;

  const toggleMessage = (messageId) => {
    setExpandedMessages((prevState) => ({
      ...prevState,
      [messageId]: !prevState[messageId],
    }));
  };

  const toggleQuote = (messageId) => {
    setIsQuoteExpanded((prevState) => ({
      ...prevState,
      [messageId]: !prevState[messageId],
    }));
  };

  const parseMessage = (payload) => {
    let htmlContent = null;
    const inlineImages = {};

    const walkMimeTree = (part) => {
      if (part.mimeType === 'multipart/alternative' && part.parts) {
        // Process multipart/alternative to select the best version
        const alternatives = part.parts;
        for (const altPart of alternatives) {
          if (altPart.mimeType === 'text/html' && altPart.body?.data) {
            // Prefer text/html
            htmlContent = decodeBase64(altPart.body.data);
            return;
          } else if (altPart.mimeType === 'text/plain' && altPart.body?.data && !htmlContent) {
            // Fallback to text/plain if no text/html found yet
            htmlContent = `<pre>${decodeBase64(altPart.body.data)}</pre>`;
          }
        }
      } else if (part.mimeType === 'multipart/related' && part.parts) {
        // Process multipart/related, which may contain inline images and HTML content
        for (const relatedPart of part.parts) {
          if (relatedPart.mimeType === 'text/html' && relatedPart.body?.data) {
            htmlContent = decodeBase64(relatedPart.body.data);
          } else if (relatedPart.mimeType?.startsWith('image/') && relatedPart.body?.data) {
            // Collect inline images
            const contentIdHeader = relatedPart.headers?.find((h) => h.name.toLowerCase() === 'content-id');
            if (contentIdHeader) {
              const contentId = contentIdHeader.value.replace(/[<>]/g, '');
              inlineImages[contentId] = `data:${relatedPart.mimeType};base64,${relatedPart.body.data}`;
            }
          } else if (relatedPart.mimeType?.startsWith('multipart/')) {
            // Recursively process nested multiparts
            walkMimeTree(relatedPart);
          }
        }
      } else if (part.mimeType === 'text/html' && part.body?.data && !htmlContent) {
        // Direct text/html part
        htmlContent = decodeBase64(part.body.data);
      } else if (part.mimeType?.startsWith('image/') && part.body?.data) {
        // Collect inline images
        const contentIdHeader = part.headers?.find((h) => h.name.toLowerCase() === 'content-id');
        if (contentIdHeader) {
          const contentId = contentIdHeader.value.replace(/[<>]/g, '');
          inlineImages[contentId] = `data:${part.mimeType};base64,${part.body.data}`;
        }
      } else if (part.parts) {
        // Recursively process other multipart types
        for (const subPart of part.parts) {
          walkMimeTree(subPart);
        }
      }
    };

    // Start walking the MIME tree from the payload
    walkMimeTree(payload);

    // If no htmlContent was found, check for text/plain in the top-level payload
    if (!htmlContent && payload.mimeType === 'text/plain' && payload.body?.data) {
      htmlContent = `<pre>${decodeBase64(payload.body.data)}</pre>`;
    }

    // Separate new content and quoted history
    let textContent = '';
    if (htmlContent) {
      const { newContent, quotedContent } = splitMessageContent(htmlContent);

      // Convert newContent to plain text for snippet
      textContent = htmlToText(newContent, {
        wordwrap: false,
        selectors: [{ selector: 'img', format: 'skip' }],
      });

      // Generate a snippet from the plain text content
      const snippet = textContent.substring(0, 200); // Adjust length as needed

      return { htmlContent: newContent, quotedContent, inlineImages, snippet };
    } else {
      return { htmlContent: '', quotedContent: '', inlineImages: {}, snippet: '' };
    }
  };

  const splitMessageContent = (htmlContent) => {
    let newContent = htmlContent;
    let quotedContent = '';

    // Define patterns that indicate the start of quoted content
    const patterns = [
      /<div class="gmail_quote">/i,
      /<blockquote[^>]*>/i,
      /<hr>/i,
      /On\s.+?wrote:/i,
      /Le\s.+?a écrit :/i, // French pattern
      /Den\s.+?skrev\s.+?:/i, // Swedish pattern
      /Am\s.+?schrieb\s.+?:/i, // German pattern
      /El\s.+?escribió:/i, // Spanish pattern
      /Em\s.+?escreveu:/i, // Portuguese pattern
    ];

    for (let pattern of patterns) {
      const splitContent = newContent.split(pattern);
      if (splitContent.length > 1) {
        newContent = splitContent[0];
        quotedContent = htmlContent.substring(newContent.length);
        break;
      }
    }

    return { newContent, quotedContent };
  };

  const decodeBase64 = (data) => {
    // Replace URL-safe characters
    const base64 = data.replace(/-/g, '+').replace(/_/g, '/');

    // Decode Base64 string into a byte array
    const rawData = window.atob(base64);
    const byteArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; i++) {
      byteArray[i] = rawData.charCodeAt(i);
    }

    // Decode UTF-8 byte array into a string
    const textDecoder = new TextDecoder('utf-8');
    const decodedString = textDecoder.decode(byteArray);

    return decodedString;
  };

  const sanitizeHtml = (html) => {
    return DOMPurify.sanitize(html, {
      ADD_TAGS: ['img'],
      ADD_ATTR: ['src', 'alt', 'height', 'width', 'style'],
    });
  };

  return (
    <ThreadContainer>
      {/* Visa felmeddelanden om de finns */}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      
      {thread.messages.map((message, index) => {
        const isExpanded = expandedMessages[message.id] || false;
        const headers = message.payload.headers;
        const fromHeader = headers.find((h) => h.name === 'From')?.value || 'Unknown Sender';
        const subjectHeader = headers.find((h) => h.name === 'Subject')?.value || '(No Subject)';
        const dateText = new Date(parseInt(message.internalDate)).toLocaleString();

        // Använd parseMessage för att få htmlContent, quotedContent, inlineImages, och snippet
        const { htmlContent, quotedContent, inlineImages, snippet } = parseMessage(message.payload);

        // Ersätt CID-referenser med faktiska data URLs i htmlContent
        let finalHtmlContent = htmlContent;
        if (finalHtmlContent) {
          Object.keys(inlineImages).forEach((cid) => {
            const cidRegex = new RegExp(`cid:${cid}`, 'g');
            finalHtmlContent = finalHtmlContent.replace(cidRegex, inlineImages[cid]);
          });

          // Sanitize the final HTML content
          finalHtmlContent = sanitizeHtml(finalHtmlContent);
        } else {
          finalHtmlContent = '<p>(No Content)</p>';
        }

        // Sanitize the quoted content
        let sanitizedQuotedContent = '';
        if (quotedContent) {
          sanitizedQuotedContent = sanitizeHtml(quotedContent);
        }

        return (
          <MessageItem key={message.id}>
            <AccordionHeader onClick={() => toggleMessage(message.id)}>
              <div style={{ flex: 1 }}>
                {index === 0 && <Subject>{subjectHeader}</Subject>}
                <Sender>{fromHeader}</Sender>
                {!isExpanded && <MessageSnippet>{snippet}</MessageSnippet>}
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <DateText>{dateText}</DateText>
              </div>
            </AccordionHeader>
            {isExpanded && (
              <AccordionContent>
                <MessageContent>
                  <div
                    dangerouslySetInnerHTML={{ __html: finalHtmlContent }}
                  ></div>
                  {quotedContent && (
                    <CollapsedQuote>
                      {!isQuoteExpanded[message.id] ? (
                        <ShowQuoteButton onClick={() => toggleQuote(message.id)}>
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </ShowQuoteButton>
                      ) : (
                        <div>
                          <HideQuoteButton onClick={() => toggleQuote(message.id)}>
                            <FontAwesomeIcon icon={faEllipsisH} />
                          </HideQuoteButton>
                          <div
                            dangerouslySetInnerHTML={{ __html: sanitizedQuotedContent }}
                          ></div>
                        </div>
                      )}
                    </CollapsedQuote>
                  )}
                </MessageContent>
              </AccordionContent>
            )}
          </MessageItem>
        );
      })}

      {/* Reply Button and Reply Form */}
      <ReplyButton onClick={() => setShowReplyForm(!showReplyForm)}>
        {showReplyForm ? 'Stäng' : 'Svara'}
      </ReplyButton>

      {showReplyForm && (
        <ReplyForm
          threadId={threadId}
          onReplySent={() => {
            setShowReplyForm(false);
            fetchThread(); // Uppdatera tråden för att visa det nya svaret
          }}
          onCancel={() => setShowReplyForm(false)}
        />
      )}
    </ThreadContainer>
  );
};

export default ThreadView;
