// src/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('accessToken'));
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'));

  // Uppdatera localStorage när authToken ändras
  useEffect(() => {
    if (authToken) {
      localStorage.setItem('accessToken', authToken);
    } else {
      localStorage.removeItem('accessToken');
    }
  }, [authToken]);

  // Uppdatera localStorage när refreshToken ändras
  useEffect(() => {
    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
    } else {
      localStorage.removeItem('refreshToken');
    }
  }, [refreshToken]);

  // Logout-funktion
  const logout = () => {
    setAuthToken(null);
    setRefreshToken(null);
  };

  return (
    <AuthContext.Provider value={{ authToken, setAuthToken, refreshToken, setRefreshToken, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
