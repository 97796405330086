import React, { useState } from 'react';
import styled from 'styled-components';
import NyKund from './NyKund';

const Button = styled.button`
  background-color: #ff8c00;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: #e07b00;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 40%;
`;

const DropdownContent = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 163%;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px;
`;

const SkapaButton = styled(Button)`
  margin-top: 10px;
  width: 50%;
  margin-left: 25%;
  border-radius: 10px;
`;

const NyKundKnapp = ({ refreshCustomers }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSkapaKund = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/customers`, {  // Corrected line
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, phone }),
      });

      if (!response.ok) {
        throw new Error('Failed to create customer');
      }

      const newCustomer = await response.json();
      //console.log('Customer created:', newCustomer);

      // Refresh the customer list in the parent component
      refreshCustomers();

      // Optionally reset the form fields after successful creation
      setIsOpen(false);
      setName('');
      setEmail('');
      setPhone('');
    } catch (error) {
      console.error('Error creating customer:', error.message);
    }
  };

  return (
    <DropdownContainer>
      <Button onClick={toggleDropdown}>Skapa ny kund</Button>
      <DropdownContent isOpen={isOpen}>
        <NyKund 
          name={name} 
          setName={setName} 
          email={email} 
          setEmail={setEmail} 
          phone={phone} 
          setPhone={setPhone} 
        />
        <SkapaButton onClick={handleSkapaKund}>Skapa kund</SkapaButton>
      </DropdownContent>
    </DropdownContainer>
  );
};

export default NyKundKnapp;
