import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { TicketContext } from '../context/TicketContext';

import fardigtImage from '../components/Labels/Fardigt.jpg';
import skaGorasImage from '../components/Labels/skagoras.jpg';
import vantarDelarImage from '../components/Labels/vantardelar.jpg';
import vantarProduktImage from '../components/Labels/vantarprodukt.jpg';
import vantarSulmattImage from '../components/Labels/vantarsulmatt.jpg';

const statusImages = {
  'Färdigt': fardigtImage,
  'Ska göras': skaGorasImage,
  'Väntar på delar': vantarDelarImage,
  'Väntar på produkt': vantarProduktImage,
  'Väntar på sulmått': vantarSulmattImage,
};

const TicketListContainer = styled.div`
  width: 100%;
  max-width: 1141px;
  background: #FFFFFF;
  border: 0.3px solid #B9B9B9;
  border-radius: 14px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
`;

const TicketHeader = styled.div`
  display: flex;
  padding: 10px 20px;
  background: #FCFDFD;
  border-bottom: 0.6px solid #D5D5D5;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 800;
  font-size: 14px;
  color: #202224;

  /* Use the same flex-basis as TicketCell for alignment */
  div:nth-child(1) { flex: 0 0 0%; text-align: right; } /* ID */
  div:nth-child(2) { flex: 0 0 15.7%; text-align: right; } /* Name */
  div:nth-child(3) { flex: 0 0 22.6%; text-align: right; } /* Product */
  div:nth-child(4) { flex: 0 0 20.5%; text-align: right; } /* Received Date */
  div:nth-child(5) { flex: 0 0 23.4%; text-align: right; } /* Due Date */
  div:nth-child(6) { flex: 0 0 12%; text-align: right; } /* Status */

  @media (max-width: 768px) {
    width: 100%;
    padding: 5px 0;
  }
`;

const TicketRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 0.4px solid #979797;
  transition: background 0.3s;

  &:hover {
    background: #f0f0f0;
  }

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TicketCell = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  color: #202224;
  opacity: 0.9;

  &:nth-child(1) { flex: 0 0 13%; } /* ID */
  &:nth-child(2) { flex: 0 0 20%; } /* Name */
  &:nth-child(3) { flex: 0 0 19.5%; } /* Product */
  &:nth-child(4) { flex: 0 0 20.5%; } /* Received Date */
  &:nth-child(5) { flex: 0 0 17%; } /* Due Date */
  &:nth-child(6) { flex: 0 0 19%; } /* Status */

  @media (max-width: 768px) {
    width: 100%;
    padding: 5px 0;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background: #FCFDFD;
  border-top: 0.6px solid #D5D5D5;
`;

const PaginationButton = styled.button`
  background: none;
  border: none;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #202224;
  cursor: pointer;

  &:hover {
    color: #000000;
  }

  &:disabled {
    color: #cccccc;
    cursor: not-allowed;
  }
`;

const TicketList = ({ customerId, filteredTickets }) => {
  const { tickets, setTickets, sortTicketsByStatus } = useContext(TicketContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const ticketsPerPage = 10;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        setLoading(true);
        let url = `${process.env.REACT_APP_API_BASE_URL}/api/tickets`; // Default fetch all tickets

        if (customerId) {
          url += `?customer_id=${customerId}`; // Fetch specific customer tickets if customerId is provided
        }

        //console.log(`Fetching tickets from: ${url}`);
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Failed to fetch tickets');
        }

        const data = await response.json();
        const sortedTickets = sortTicketsByStatus(data);
        setTickets(sortedTickets);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching tickets:', error);
        setLoading(false);
      }
    };

    fetchTickets();
  }, [customerId, setTickets, sortTicketsByStatus]);

  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;

  // Check if filtered tickets are passed down from the parent; otherwise, use the fetched tickets
  const displayTickets = filteredTickets?.length ? filteredTickets : tickets;
  const currentTickets = displayTickets.slice(indexOfFirstTicket, indexOfLastTicket);

  const handleTicketClick = (ticketId) => {
    navigate(`/ticket/${ticketId}`, { state: { page: currentPage } });
  };

  const totalPages = Math.ceil(displayTickets.length / ticketsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <TicketListContainer>
      <TicketHeader>
        <div>ID</div>
        <div>NAMN</div>
        <div>PRODUKT</div>
        <div>MOTTAGET</div>
        <div>SENAST KLART</div>
        <div>STATUS</div>
      </TicketHeader>
      {currentTickets.map((ticket) => (
        //console.log('Ticket:', ticket), // Add this line to log the ticket data
        <TicketRow key={ticket.ticket_id} onClick={() => handleTicketClick(ticket.ticket_id)}>
          <TicketCell>{ticket.ticket_id}</TicketCell>
          <TicketCell>{ticket.customer_name}</TicketCell>
          <TicketCell>{ticket.skida || ticket.pjaxa || ticket.product || 'N/A'}</TicketCell>
          <TicketCell>{new Date(ticket.receiveddate).toLocaleDateString()}</TicketCell>
          <TicketCell>{new Date(ticket.senast_klar).toLocaleDateString()}</TicketCell>
          <TicketCell>
            <img
              src={statusImages[ticket.status]}
              alt={ticket.status}
              style={{ height: '27px' }} 
            />
          </TicketCell>
        </TicketRow>
      ))}
      <PaginationContainer>
        <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
          &lt; Föregående
        </PaginationButton>
        <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
          Nästa &gt;
        </PaginationButton>
      </PaginationContainer>
    </TicketListContainer>
  );
};

export default TicketList;
