import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { sv } from 'date-fns/locale';

const FormContainer = styled.div`
  background: #FFFFFF;
  border: 0.6px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const InputField = styled.div`
  flex-basis: 48%;
  background: #FFFFFF;
  border-radius: 19px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  text-align: left;
  margin-bottom: 5px;
`;

const Input = styled.input`
  background: #FFFFFF;
  border: 0.6px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  padding: 10px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  outline: none;
  flex-grow: 1;
`;

const DateInput = styled(Input)`
  padding: 10px;
  font-size: 16px;
  width: 95%;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 48%;
  margin-top: 30px;
`;

const CheckboxLabel = styled.label`
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  color: #1E1E1E;
`;

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  background: #2C2C2C;
  border-radius: 4px;
  margin-right: 10px;
`;

const PjaxserviceForm = ({ onFormDataChange, initialData = {} }) => {
  const [pjaxa, setPjaxa] = useState(initialData.pjaxa || '');
  const [servicetyp, setServicetyp] = useState(initialData.servicetyp || '');
  const [selectedDate, setSelectedDate] = useState(initialData.senast_klar ? new Date(initialData.senast_klar) : null);
  const [mottagetAv, setmottagetAv] = useState(initialData.mottaget_av || '');
  const [isChecked, setIsChecked] = useState(initialData.is_checked || false);

  useEffect(() => {
    onFormDataChange({
      pjaxa,
      servicetyp,
      senast_klar: selectedDate ? selectedDate.toISOString().split('T')[0] : null,
      mottaget_av: mottagetAv,
      is_checked: isChecked,
    });
  }, [pjaxa, servicetyp, selectedDate, mottagetAv, isChecked, onFormDataChange]);

  return (
    <FormContainer>
      <InputRow>
        <InputField>
          <Label>Pjäxa</Label>
          <Input type="text" value={pjaxa} onChange={(e) => setPjaxa(e.target.value)} />
        </InputField>
        <InputField>
          <Label>Servicetyp</Label>
          <Input type="text" value={servicetyp} onChange={(e) => setServicetyp(e.target.value)} />
        </InputField>
      </InputRow>
      
      <InputRow>
        <InputField>
          <Label>Senast klar</Label>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            customInput={<Input as={DateInput} value={selectedDate ? selectedDate.toLocaleDateString() : ''} />}
            dateFormat="dd/MM/yyyy"
            placeholderText="Välj datum"
            locale={sv}
          />
        </InputField>
        <InputField>
          <Label>mottaget av</Label>
          <Input type="text" value={mottagetAv} onChange={(e) => setmottagetAv(e.target.value)} />
        </InputField>
      </InputRow>
      <InputRow>
        <InputField></InputField>
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <CheckboxLabel>Betalt</CheckboxLabel>
        </CheckboxContainer>
      </InputRow>
    </FormContainer>
  );
};

export default PjaxserviceForm;
