import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import NyttArandeKnapp from './NyttArendeKnapp';

const KundListContainer = styled.div`
  width: 100%;
  max-width: 1141px;
  background: #FFFFFF;
  border: 0.3px solid #B9B9B9;
  border-radius: 14px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
`;

const KundHeader = styled.div`
  display: flex;
  padding: 10px 20px;
  background: #FCFDFD;
  border-bottom: 0.6px solid #D5D5D5;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 800;
  font-size: 14px;
  color: #202224;

  div:nth-child(1) { flex: 0 0 0%; text-align: right; } /* ID */
  div:nth-child(2) { flex: 0 0 18%; text-align: right; } /* Namn */
  div:nth-child(3) { flex: 0 0 19.5%; text-align: right; } /* E-mail */
  div:nth-child(4) { flex: 0 0 20.5%; text-align: right; } /* Telefon */
  div:nth-child(5) { flex: 0 0 17%; text-align: right; } /* Butik */
  div:nth-child(6) { flex: 0 0 18%; text-align: right; } /* Skapa */
`;

const KundRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 0.4px solid #979797;
  transition: background 0.3s;

  &:hover {
    background: #f0f0f0;
  }

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const KundCell = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  color: #202224;
  opacity: 0.9;

  &:nth-child(1) { flex: 0 0 15.5%; } /* ID */
  &:nth-child(2) { flex: 0 0 19%; } /* Namn */
  &:nth-child(3) { flex: 0 0 19.8%; } /* E-mail */
  &:nth-child(4) { flex: 0 0 20.5%; } /* Telefon */
  &:nth-child(5) { flex: 0 0 12%; } /* Butik */
  &:nth-child(6) { flex: 0 0 17%; } /* Skapa */
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background: #FCFDFD;
  border-top: 0.6px solid #D5D5D5;
`;

const PaginationButton = styled.button`
  background: none;
  border: none;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #202224;
  cursor: pointer;

  &:hover {
    color: #000000;
  }

  &:disabled {
    color: #cccccc;
    cursor: not-allowed;
  }
`;

const KundLista = ({ searchTerm }) => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const customersPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/customers`);
        if (!response.ok) {
          throw new Error('Failed to fetch customers');
        }
        const data = await response.json();
        setCustomers(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomers();
  }, []);

  // Filter customers based on the search term
  const filteredCustomers = customers.filter((customer) =>
    customer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate the indices for the current page
  const indexOfLastCustomer = currentPage * customersPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
  const currentCustomers = filteredCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer);

  const totalPages = Math.ceil(filteredCustomers.length / customersPerPage);

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handleCustomerClick = (id) => {
    navigate(`/customers/${id}`);
  };

  const handleCreateTicket = (customer) => {
    navigate('/nyttarende', { state: { customer } }); // Navigate to new ticket with customer details
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <KundListContainer>
        <KundHeader>
          <div>ID</div>
          <div>Namn</div>
          <div>E-mail</div>
          <div>Telefon</div>
          <div>Butik</div>
          <div>Skapa</div>
        </KundHeader>
        {currentCustomers.map((customer) => (
          <KundRow key={customer.id} onClick={() => handleCustomerClick(customer.id)}>
            <KundCell>{customer.id}</KundCell>
            <KundCell>{customer.name}</KundCell>
            <KundCell>{customer.email}</KundCell>
            <KundCell>{customer.phone}</KundCell>
            <KundCell>{customer.store}</KundCell>
            <KundCell onClick={(e) => e.stopPropagation()}>
            {/* Prevent row click when "Skapa nytt ärende" button is clicked */}
            <NyttArandeKnapp customer={customer} onClick={() => handleCreateTicket(customer)} />
            </KundCell>
          </KundRow>
        ))}
      <PaginationContainer>
        <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
          &lt; Föregående
        </PaginationButton>
        <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
          Nästa &gt;
        </PaginationButton>
      </PaginationContainer>
    </KundListContainer>
    </>
  );
};

export default KundLista;
