// src/components/Task.js
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { device } from '../styles/breakpoints'; // Importera breakpoints

const TaskContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
  gap: 10px;
  align-items: center;
  background: #FFFFFF;
  border-radius: 14px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);

  @media ${device.tablet} {
    grid-template-columns: 1fr 2fr;
    grid-template-areas:
      "id type"
      "product dueDate"
      "action action";
    gap: 5px;
    padding: 10px;
  }
`;

const TaskItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F5F6FA;
  border: 0.6px solid #D5D5D5;
  border-radius: 19px;
  padding: 5px;
  text-align: center;
  width: 70%;

  @media ${device.tablet} {
    grid-area: ${props => props.area};
    justify-content: ${props => (props.area === 'action' ? 'center' : 'left')};
  }
`;

const TaskText = styled.p`
  font-weight: 700;
  font-size: 0.75rem;
  color: #202224;

  @media ${device.tablet} {
    font-size: 0.8rem;
  }
`;

const TaskTypeText = styled.p`
  font-weight: 600;
  font-size: 0.75rem;
  color: #555555;

  @media ${device.tablet} {
    font-size: 0.8rem;
  }
`;

const TaskButton = styled.button`
  background: #FF9500;
  color: white;
  border: none;
  border-radius: 14px;
  padding: 10px 15px;
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    background: #e08900;
  }

  @media ${device.tablet} {
    width: 100%;
    padding: 8px 10px;
    font-size: 0.8rem;
  }
`;

const Task = ({ taskId, taskType, taskName, dueDate, onButtonClick }) => {
  return (
    <TaskContainer>
      <TaskItem>
        <TaskText>{taskId}</TaskText>
      </TaskItem>
      <TaskItem>
        <TaskTypeText>{taskType}</TaskTypeText> 
      </TaskItem>
      <TaskItem>
        <TaskText>{taskName}</TaskText>
      </TaskItem>
      <TaskItem>
        <TaskText>{dueDate}</TaskText>
      </TaskItem>
      
        <TaskButton onClick={onButtonClick}>Gå till ärende</TaskButton>
      
    </TaskContainer>
  );
};

// Uppdaterade PropTypes för att inkludera taskType
Task.propTypes = {
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  taskType: PropTypes.string.isRequired, // Ny prop för Ärendetyp
  taskName: PropTypes.string.isRequired,
  dueDate: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

export default Task;
