// src/styles/theme.js
export const theme = {
    colors: {
      primary: '#ffffff',
      primaryHover: '#e08900',
      secondary: '#4CAF50',
      background: '#F5F6FA',
      text: '#202224',
      sidebarBg: '#FFFFFF',
      // Lägg till fler färger efter behov
    },
    fonts: {
      main: "'Nunito Sans', sans-serif",
      // Lägg till fler typsnitt om nödvändigt
    },
  };
  
  