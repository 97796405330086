// src/components/Topbar.js
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FaBars, FaTimes, FaSearch } from 'react-icons/fa'; // För hamburgermeny ikoner och sökikon
import { useTickets } from '../context/TicketContext';
import debounce from 'lodash.debounce';
import SearchDropdown from './SearchDropdown';
import { useNavigate } from 'react-router-dom';
import { device } from '../styles/breakpoints'; // Importera breakpoints

const TopbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 72.72px;
  background: #FFFFFF;
  padding: 0 20px;
  z-index: 1000; /* Ensures the topbar stays above other content */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 241.5px; /* Adjust this value to match the width of your sidebar */
  right: 0;

  @media (max-width: 768px) {
    left: 0;
    height: 60px;
    padding: 0 10px;
  }

  @media (max-width: 480px) {
    height: 50px;
  }
`;

const MenuIcon = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
    font-size: 22px;
    color: #202224;
  }

  svg {
    font-size: 22px;
    color: #202224;
    cursor: pointer;
  }
`;

const Search = styled.div`
  flex: 2;
  position: relative;

  input {
    width: 100%;
    height: 45px;
    padding-left: 45px;
    border-radius: 19px;
    border: 0.6px solid #D5D5D5;
    background: #F5F6FA;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    color: #202224;
    opacity: 0.9; /* Increased opacity for better visibility */
    outline: none;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    color: #202224;
    opacity: 0.7;
    pointer-events: none;
  }

  /* Dropdown styling */
  .dropdown {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    border-radius: 5px;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 2000; /* Above the topbar */
  }

  .dropdown-item {
    padding: 10px 15px;
    cursor: pointer;
    transition: background 0.2s;
  }

  .dropdown-item:hover {
    background: #F0F0F0;
  }

  .no-results {
    padding: 10px 15px;
    color: #888888;
  }
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 3;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
  }

  .name {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #404040;
  }

  .role {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #565656;
    margin-left: 10px;
    margin-right: 20px;
  }

  svg {
    color: #565656;
    margin-left: 5px;
    cursor: pointer;
  }
`;

const Topbar = ({ toggleSidebar, isSidebarOpen }) => {
  const { setSearchQuery, tickets } = useTickets();
  const [inputValue, setInputValue] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate(); // Initialize useNavigate

  // Debounce the search query update
  const debouncedSetSearchQuery = useCallback(
    debounce((value) => {
      setSearchQuery(value);
      setIsDropdownVisible(value.trim() !== '');
    }, 300),
    [setSearchQuery]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    debouncedSetSearchQuery(value);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      debouncedSetSearchQuery.cancel();
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [debouncedSetSearchQuery]);

  const handleItemClick = (ticket) => {
    navigate(`/ticket/${ticket.ticket_id}`);
    setIsDropdownVisible(false);
  };

  return (
    <TopbarContainer>
      <MenuIcon onClick={toggleSidebar}>
        {isSidebarOpen ? <FaTimes /> : <FaBars />}
      </MenuIcon>
      <Search ref={searchRef}>
        <input 
          type="text" 
          placeholder="Sök efter ärendenummer eller kundnamn..." 
          value={inputValue}
          onChange={handleSearchChange}
          aria-label="Search Tickets"
        />
        <FaSearch />
        {isDropdownVisible && (
          <div className="dropdown">
            <SearchDropdown 
              tickets={tickets}
              onItemClick={handleItemClick}
            />
          </div>
        )}
      </Search>
      <Icons>
        <Profile>
          <img src="path/to/profile-image.jpg" alt="Profile" />
          <div>
            <span className="name">Skistore</span>
            <span className="role">Admin</span>
          </div>
          <FaTimes />
        </Profile>
      </Icons>
    </TopbarContainer>
  );
};

export default Topbar;

