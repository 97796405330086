// ReplyForm.js
import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

// Styled Components
const ReplyFormContainer = styled.div`
  margin-top: 20px;
`;

const ReplyTextarea = styled.textarea`
  width: 95%;
  min-height: 100px;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const ReplyFormActions = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 10px;
`;

const SendButton = styled.button`
  padding: 8px 16px;
  background-color: #28a745; /* Grön för skicka */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const CancelButton = styled.button`
  padding: 8px 16px;
  background-color: #6c757d; /* Grå för avbryt */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

// Component
const ReplyForm = ({ threadId, onReplySent, onCancel }) => {
  const [replyContent, setReplyContent] = useState('');
  const [isSending, setIsSending] = useState(false);

  const handleSendReply = async () => {
    if (!threadId) {
      alert('Ingen tråd-ID angiven.');
      return;
    }

    if (!replyContent.trim()) {
      alert('Vänligen skriv ett meddelande.');
      return;
    }

    setIsSending(true);

    try {
      //console.log('Sending reply to threadId:', threadId);
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/threads/${threadId}/reply`, {
        replyContent,
      });

      if (response.status === 200) {
        alert('Svar skickat framgångsrikt!');
        setReplyContent('');
        onReplySent(); // Meddela föräldern att uppdatera tråden eller utföra post-svar åtgärder
      } else {
        alert('Misslyckades med att skicka svar.');
      }
    } catch (error) {
      console.error('Error sending reply:', error);
      alert('Ett fel uppstod vid sändning av svaret.');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <ReplyFormContainer>
      <ReplyTextarea
        value={replyContent}
        onChange={(e) => setReplyContent(e.target.value)}
        placeholder="Skriv meddelande..."
        disabled={isSending}
      />
      <ReplyFormActions>
        <SendButton onClick={handleSendReply} disabled={isSending}>
          {isSending ? 'Skickar...' : 'Skicka'}
        </SendButton>
        <CancelButton onClick={onCancel} disabled={isSending}>
          Avbryt
        </CancelButton>
      </ReplyFormActions>
    </ReplyFormContainer>
  );
};

export default ReplyForm;
