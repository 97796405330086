// src/components/TasksContainer.js
import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import Task from './Task';
import { useTickets } from '../context/TicketContext';
import { useNavigate } from 'react-router-dom';
import { device } from '../styles/breakpoints'; // Importera breakpoints

const Container = styled.div`
  margin: 40px 20px;

  @media ${device.tablet} {
    margin: 20px 10px;
  }
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 1rem;
  color: #202224;
  margin-bottom: 20px;
  opacity: 0.7;

  @media ${device.tablet} {
    font-size: 0.9rem;
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
  gap: 10px;
  font-weight: 600;
  font-size: 0.9rem;
  color: #202224;
  margin-bottom: 10px;
  padding: 10px 15px;
  background: #F5F6FA;
  border-radius: 14px;

  @media ${device.tablet} {
    grid-template-columns: 1fr 2fr;
    grid-template-areas:
      "id type"
      "product dueDate"
      "action action";
    gap: 5px;
    text-align: center;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const PageButton = styled.button`
  background: none;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  margin: 0 10px;
  cursor: pointer;
  color: ${({ disabled }) => (disabled ? '#ccc' : '#000')};

  &:disabled {
    cursor: not-allowed;
  }

  @media ${device.tablet} {
    margin: 5px 0;
  }
`;

const TasksContainer = ({ onTaskButtonClick }) => {
  const { tickets, isLoading, error } = useTickets();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const tasksPerPage = 5;

  // Funktion för att hämta ärenden som ska vara klara den här veckan och har status "Ska göras"
  const getCurrentWeekTasks = () => {
    const startOfWeek = new Date();
    startOfWeek.setHours(0, 0, 0, 0);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);

    return tickets.filter(ticket => {
      const taskDueDate = new Date(ticket.senast_klar);
      const isWithinWeek = taskDueDate >= startOfWeek && taskDueDate <= endOfWeek;
      const isStatusSkaGoras = ticket.status === 'Ska göras';
      return isWithinWeek && isStatusSkaGoras;
    });
  };

  const currentWeekTasks = useMemo(getCurrentWeekTasks, [tickets]);

  const totalPages = Math.ceil(currentWeekTasks.length / tasksPerPage);
  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = currentWeekTasks.slice(indexOfFirstTask, indexOfLastTask);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(prev => prev + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(prev => prev - 1);
  };

  if (isLoading) {
    return (
      <Container>
        <Title>Ska vara klart den här veckan</Title>
        <p>Laddar ärenden...</p>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Title>Ska vara klart den här veckan</Title>
        <p>Fel vid hämtning av ärenden: {error}</p>
      </Container>
    );
  }

  return (
    <Container>
      <Title>Ska vara klart den här veckan</Title>
      <Header>
        <p>Ärendenummer</p>
        <p>Ärendetyp</p>
        <p>Produkt</p>
        <p>Klart till</p>
        
      </Header>
      {currentTasks.map(task => (
        <Task
          key={task.id}
          taskId={task.ticket_id}
          taskType={task.type}
          taskName={task.product}
          dueDate={new Date(task.senast_klar).toISOString().split('T')[0]}
          onButtonClick={() => navigate(`/ticket/${task.ticket_id}`)}
        />
      ))}
      {totalPages > 1 && (
        <Pagination>
          <PageButton onClick={handlePrevPage} disabled={currentPage === 1}>
            &lt; Föregående
          </PageButton>
          <span>
            Sida {currentPage} av {totalPages}
          </span>
          <PageButton onClick={handleNextPage} disabled={currentPage === totalPages}>
            Nästa &gt;
          </PageButton>
        </Pagination>
      )}
    </Container>
  );
};

export default TasksContainer;
