// src/styles/globalStyles.js
import { createGlobalStyle } from 'styled-components';
import { device } from './breakpoints';

const GlobalStyles = createGlobalStyle`
  /* Box Sizing */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  /* Reset Margin and Padding */
  body, h1, h2, h3, h4, p, ul, li, button {
    margin: 0;
    padding: 0;
  }

  /* Body Styling */
  body {
    font-family: ${({ theme }) => theme.fonts.main};
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    line-height: 1.6;
    overflow: hidden; /* För att förhindra dubbel scrollning */
  }

  /* Links */
  a {
    text-decoration: none;
    color: inherit;
  }

  /* Buttons */
  button {
    font-family: ${({ theme }) => theme.fonts.main};
    cursor: pointer;
    background: none;
    border: none;
  }

  /* Scrollbar Styling */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary}; 
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.primaryHover}; 
  }

  /* Other global styles as needed */
`;

export default GlobalStyles;
