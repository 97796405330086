// src/MessageProvider.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import { useAxiosInstance } from '../axiosInstance'; // Importera den anpassade Axios-instansen
import { AuthContext } from '../AuthContext'; // Importera AuthContext
import labelMap from '../config/labelMap';

// Create the context
export const MessageContext = createContext();

// API function to fetch unread message counts from Gmail
const fetchUnreadMessagesFromGmail = async (axiosInstance) => {
  try {
    const response = await axiosInstance.get('/api/email/unread-count'); // Rutan '/api/email/unread-count' nu utan basURL
    return response.data; // Response should be an object with label counts
  } catch (error) {
    console.error('Error fetching unread Gmail messages:', error);
    return {
      inbox: 0,
      montering: 0,
      skidservice: 0,
      pjaxservice: 0,
      reklamationer: 0,
      specialorder: 0,
    };  // Return zeros in case of error
  }
};

// API function to fetch unread message counts from Facebook or Instagram
const fetchUnreadMessagesFromMeta = async (platform) => {
  // Simulate API call to Meta for unread messages (Facebook or Instagram)
  if (platform === 'Facebook Messenger') {
    return new Promise((resolve) => {
      setTimeout(() => resolve(1), 1000);  // Simulate 1 unread message in Messenger
    });
  } else if (platform === 'Instagram Direct Message') {
    return new Promise((resolve) => {
      setTimeout(() => resolve(6), 1000);  // Simulate 6 unread messages in Instagram
    });
  }
};

// Function to fetch threads for a specific Gmail label
const fetchGmailThreadsForLabel = async (axiosInstance, label, page = 1, messagesPerPage = 50) => {
  try {
    const response = await axiosInstance.get('/api/email/threads', {
      params: {
        label,
        page,
        messagesPerPage,
      },
    });
    return response.data;  // This will now return detailed threads with metadata
  } catch (error) {
    console.error(`Error fetching Gmail threads for label ${label}:`, error);
    return [];
  }
};

// Updated function to fetch threads for the active tab (based on platform/label)
const fetchMessagesForTab = async (axiosInstance, platform, page = 1, messagesPerPage = 50) => {
  try {
    if (platform === 'inbox') {
      // Fetch threads from all labels and combine them
      const labelsToFetch = Object.values(labelMap);
      let allThreads = [];

      const fetchedThreads = await Promise.all(
        labelsToFetch.map(label =>
          fetchGmailThreadsForLabel(axiosInstance, label, page, messagesPerPage)
        )
      );

      allThreads = fetchedThreads.flat();
      allThreads.sort((a, b) => b.internalDate - a.internalDate);

      return allThreads.slice(0, messagesPerPage);
    } else if (labelMap[platform]) {
      return fetchGmailThreadsForLabel(axiosInstance, labelMap[platform], page, messagesPerPage);
    } else {
      return [];
    }
  } catch (error) {
    console.error(`Error fetching messages for ${platform}:`, error);
    return [];
  }
};

const fetchTotalMessagesForTab = async (axiosInstance, platform) => {
  try {
    if (platform === 'inbox') {
      // Sum total messages across all labels
      const labelsToFetch = Object.values(labelMap);
      let total = 0;

      for (const labelId of labelsToFetch) {
        const response = await axiosInstance.get('/api/email/totalMessages', {
          params: { label: labelId },
        });
        total += response.data.total;
      }

      return total;
    } else if (labelMap[platform]) {
      const labelId = labelMap[platform];
      const response = await axiosInstance.get('/api/email/totalMessages', {
        params: { label: labelId },
      });
      return response.data.total;
    } else {
      return 0;
    }
  } catch (error) {
    console.error(`Error fetching total messages for ${platform}:`, error);
    return 0;
  }
};

// Provider component
export const MessageProvider = ({ children }) => {
  const { authToken } = useContext(AuthContext);
  const axiosInstance = useAxiosInstance(); // Använd den anpassade Axios-instansen

  const [searchTerm, setSearchTerm] = useState('');
  const [unreadMessages, setUnreadMessages] = useState({
    'Ärendemail': 0,  // Placeholder for Gmail unread messages
    'Facebook Messenger': 0,
    'Instagram Direct Message': 0,
  });

  // Fetch unread messages from Gmail and Meta APIs
  useEffect(() => {
    const fetchUnreadCounts = async () => {
      try {
        const gmailUnreadCounts = await fetchUnreadMessagesFromGmail(axiosInstance);
        const facebookUnread = await fetchUnreadMessagesFromMeta('Facebook Messenger');
        const instagramUnread = await fetchUnreadMessagesFromMeta('Instagram Direct Message');

        setUnreadMessages({
          ...gmailUnreadCounts,  // Spread Gmail unread counts from the API response
          'Facebook Messenger': facebookUnread,
          'Instagram Direct Message': instagramUnread,
        });
      } catch (error) {
        console.error('Error fetching unread messages:', error);
      }
    };

    if (authToken) { // Säkerställ att användaren är inloggad
      fetchUnreadCounts();
    }
  }, [axiosInstance, authToken]);

  return (
    <MessageContext.Provider value={{
      searchTerm,
      setSearchTerm,
      unreadMessages,
      fetchMessagesForTab: (platform, page, messagesPerPage) => fetchMessagesForTab(axiosInstance, platform, page, messagesPerPage),
      fetchTotalMessagesForTab: (platform) => fetchTotalMessagesForTab(axiosInstance, platform),
    }}>
      {children}
    </MessageContext.Provider>
  );
};

