import React, { forwardRef } from 'react';
import styled from 'styled-components';
import DynamicTicketComponent from './DynamicTicketComponent';
import Kundkort from '../components/kundkort';
import Kommentar from './kommentar';


const TicketTitle = styled.h2`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #202224;
  margin-bottom: -10px;
`;

const ContentContainer = styled.div.attrs({ role: 'content-container' })`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
`;

const CardContainer = styled.div.attrs({ role: 'card-container' })`
  flex: 0 0 48%;  /* Each card takes up about half the width of the container */
  margin-bottom: 20px;
`;

const PrintableContent = forwardRef((props, ref) => (
  <div ref={ref}>
    <TicketTitle>Ärendenummer: {props.activeTicket.ticket_id}</TicketTitle>
    <ContentContainer>
      <CardContainer>
        <Kundkort
          name={props.customer?.name}
          email={props.customer?.email}
          phone={props.customer?.phone}
        />
      </CardContainer>
      <CardContainer>
        <DynamicTicketComponent
          ticket={props.activeTicket}
          onFormDataChange={props.onFormDataChange}
        />
      </CardContainer>
      {/*<CardContainer>
        <Kundmeddelande ticket={props.activeTicket} />
      </CardContainer>*/}
      <CardContainer>
        <Kommentar ticketId={props.activeTicket.ticket_id} />
      </CardContainer>
    </ContentContainer>
  </div>
));

export default PrintableContent;
