// src/components/Sidebar.js
import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FaHome, FaTasks, FaUsers, FaEnvelope } from 'react-icons/fa'; // Importera ikoner
import { device } from '../styles/breakpoints'; // Importera breakpoints

const SidebarContainer = styled.div`
  box-sizing: border-box;
  width: 242px;
  min-width: 242px;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.sidebarBg};
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    margin-top: 55px;
    transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  }

  
`;

const Logo = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 800;
  font-size: 24px;
  color: #000000;
  margin-bottom: 40px;
  text-align: center;

  span {
    color: #FF9500;
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const Icon = styled.div`
  width: 22px;
  height: 22px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    margin-right: 5px;
  }
`;

const Label = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #202224;
`;

const activeStyle = css`
  background: #FF9500;
  color: #FFFFFF;

  ${Label} {
    color: #FFFFFF;
  }

  ${Icon} {
    visibility: hidden;
  }
`;

const MenuItem = styled(NavLink)`
  width: 80%;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 6px;
  transition: background 0.3s;
  position: relative;
  text-decoration: none;

  &.active {
    ${activeStyle}
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
    padding: 10px;
  }
`;

const Divider = styled.div`
  width: 80%;
  height: 1px;
  background-color: #E0E0E0;
  margin: 20px 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Sidebar = ({ isOpen }) => {
  const menuItems = [
    { label: 'Dashboard', icon: <FaHome />, path: '/dashboardcontent' },
    { label: 'Nytt Ärende', icon: <FaTasks />, path: '/nyttarende' },
    { label: 'Ärenden', icon: <FaTasks />, path: '/arenden' },
    { label: 'Kunder', icon: <FaUsers />, path: '/kunder' },
    { label: 'Meddelanden', icon: <FaEnvelope />, path: '/meddelanden' },
    // Lägg till fler navigationslänkar vid behov
  ];

  return (
    <SidebarContainer isOpen={isOpen}>
      <Logo>
        Service<span>Drive</span>
      </Logo>
      {menuItems.map((item, index) => (
        <MenuItem
          key={index}
          to={item.path}
          className={({ isActive }) => (isActive ? 'active' : '')} // För react-router-dom v6
        >
          {item.icon && <Icon>{item.icon}</Icon>}
          <Label>{item.label}</Label>
        </MenuItem>
      ))}
      <Divider />
    </SidebarContainer>
  );
};

export default Sidebar;
