// src/utils/axiosInstance.js

import axios from 'axios';
import { useContext, useEffect, useRef } from 'react';
import { AuthContext } from './AuthContext';

// Anpassad hook för Axios-instansen
export const useAxiosInstance = () => {
  const { authToken, setAuthToken, refreshToken, setRefreshToken, logout } = useContext(AuthContext);

  // Refs för att hålla aktuella tokens
  const authTokenRef = useRef(authToken);
  const refreshTokenRef = useRef(refreshToken);

  // Uppdatera refs när tokens förändras
  useEffect(() => {
    authTokenRef.current = authToken;
  }, [authToken]);

  useEffect(() => {
    refreshTokenRef.current = refreshToken;
  }, [refreshToken]);

  // Memoisera Axios-instansen
  const axiosInstance = useRef(null);

  if (!axiosInstance.current) {
    axiosInstance.current = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
    });

    // Request interceptor för att inkludera access token
    axiosInstance.current.interceptors.request.use(
      config => {
        if (authTokenRef.current) {
          config.headers['Authorization'] = `Bearer ${authTokenRef.current}`;
        }
        return config;
      },
      error => Promise.reject(error)
    );

    // Response interceptor för att hantera 401-fel och förnya token
    axiosInstance.current.interceptors.response.use(
      response => response,
      async error => {
        const originalRequest = error.config;

        // Hantera 401-fel och försök förnya token
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/refresh-token`, { token: refreshTokenRef.current });
            const { accessToken, newRefreshToken } = res.data;

            // Uppdatera tokens i AuthContext
            setAuthToken(accessToken);
            setRefreshToken(newRefreshToken);

            // Uppdatera Authorization-headern för den ursprungliga förfrågan
            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;

            // Utför den ursprungliga förfrågan igen med den nya token
            return axiosInstance.current(originalRequest);
          } catch (refreshError) {
            console.error('Error refreshing token:', refreshError.response ? refreshError.response.data : refreshError.message);
            logout(); // Logga ut användaren vid misslyckad tokenförnyelse
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    );
  }

  return axiosInstance.current;
};


