// src/components/SearchDropdown.js
import React from 'react';
import styled from 'styled-components';

const DropdownContainer = styled.div`
  background-color: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const DropdownItem = styled.div`
  padding: 10px 15px;
  cursor: pointer;
  transition: background 0.2s;
  color: #202224;

  &:hover, &:focus {
    background: #F0F0F0;
    outline: none;
  }
`;

const NoResults = styled.div`
  padding: 10px 15px;
  color: #888888;
`;

const SearchDropdown = ({ tickets, onItemClick }) => {
  if (tickets.length === 0) {
    return (
      <DropdownContainer>
        <NoResults>Inga resultat hittades.</NoResults>
      </DropdownContainer>
    );
  }

  return (
    <DropdownContainer>
      {tickets.slice(0, 10).map(ticket => (
        <DropdownItem 
          key={ticket.ticket_id}
          onClick={() => onItemClick(ticket)}
          tabIndex="0" // Gör elementet fokuserbart
          role="button" // Informera assistiv teknik om klickbar natur
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onItemClick(ticket);
            }
          }}
        >
          <strong>Ticket #{ticket.ticket_id}</strong> - {ticket.customer_name}
        </DropdownItem>
      ))}
    </DropdownContainer>
  );
};

export default SearchDropdown;
