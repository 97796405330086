// src/components/Widget.js
import React from 'react';
import styled from 'styled-components';

const WidgetWrapper = styled.div`
  background: #FFFFFF;
  border-radius: 14px;
  box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
  padding: 20px;
  width: 100%;
  max-width: 250px; /* Begränsa maximala bredden */
  text-align: center;
  box-sizing: border-box;
  margin: 10px;

  @media (max-width: 768px) {
    max-width: 100%; /* På mindre skärmar, låt widgeten fylla förälderns bredd */
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const WidgetContent = styled.div`
  p {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #202224;
    opacity: 0.7;
    margin-bottom: 10px;
  }

  h2 {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 700;
    font-size: 28px;
    color: #202224;
    letter-spacing: 1px;

    @media (max-width: 768px) {
      font-size: 24px;
    }

    @media (max-width: 480px) {
      font-size: 20px;
    }
  }

  svg {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      width: 35px;
      height: 35px;
    }

    @media (max-width: 480px) {
      width: 30px;
      height: 30px;
    }
  }
`;

const Widget = ({ icon, title, count }) => {
  return (
    <WidgetWrapper>
      <WidgetContent>
        {icon}
        <p>{title}</p>
        <h2>{count}</h2>
      </WidgetContent>
    </WidgetWrapper>
  );
};

export default Widget;

