import React, { useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import DynamicTicketComponent from './DynamicTicketComponent';
import Kundkort from '../components/kundkort';
import { TicketContext } from '../context/TicketContext';
import Kommentar from './kommentar';
//import Kundmeddelande from './kundmeddelande';
import Andrastatus from './Andrastatus';
import { useReactToPrint } from 'react-to-print';
import PrintableContent from './PrintableContent'; // Import the new component
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const TicketPageContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  left: 14.5%;
  right: 15%;
  top: 22%;
  bottom: 2%;
  background: #FFFFFF;
  border: 0.4px solid #B9B9B9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const ArendenContainer = styled.div`
  background: #F5F6FA;
  height: 100vh;
  padding: 20px;
  padding-top: 0px; /* 20px padding + 72.72px height of the topbar */
  overflow: hidden;
`;

const Title = styled.h1`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #202224;
  margin-bottom: 20px;
`;

const TicketTitle = styled.h2`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #202224;
  margin-bottom: -10px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
`;

const CardContainer = styled.div`
  flex: 0 0 48%;  /* Each card takes up about half the width of the container */
  margin-bottom: 20px;
`;

const StatusContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 0px;
`;

const PrintButton = styled.button`
  position: absolute;
  top: 1px;
  right: 140px; /* Adjust position as needed */
  background: #FF9500; /* Green background */
  color: black;
  padding: 9px;
  border: 1 px solid;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;

  @media print {
    display: none; /* Hide the print button when printing */
  }
`;

const PrintStyles = createGlobalStyle`
  @media print {
    @page {
      size: 73mm auto;
      margin: 0;
    }
    body {
      margin: 0;
      padding: 0;
    }

    /* Remove borders, backgrounds, and box shadows */
    * {
      box-shadow: none !important;
      background: none !important;
      border: none !important;
    }

    /* Stack all elements in simple rows */
    h1, h2, h3, h4, h5, h6,
    p, div, span, label, input, textarea {
      display: block !important;
      width: 100% !important;
      margin: 5 !important;
      padding: 2 !important;
      text-align: left !important;
    }

    /* Adjust headings */
    h2 {
      font-size: 18px !important;
      font-weight: bold !important;
      text-align: center !important;
      margin: 5px 0 !important;
    }

    /* Adjust text elements */
    p, span, label, input, textarea {
      font-size: 14px !important;
      margin: 5px 0 !important;
      padding: 5 !important;
    }

    /* Hide elements during print */
    .no-print, button, nav, footer {
      display: none !important;
    }
  }
`;


const TicketPage = () => {
  const { id } = useParams();
  const { tickets, setTickets } = useContext(TicketContext);
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');
  const componentRef = useRef();

  const handleFormDataChange = async (updatedData) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/tickets/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),  // Ensure is_checked and other fields are included
      });

      if (response.ok) {
        const data = await response.json();
        const updatedTicket = data.ticket;
        setTickets(prevTickets =>
          prevTickets.map(ticket =>
            ticket.ticket_id === id ? updatedTicket : ticket
          )
        );
        toast.success(data.message); // Visa framgångs-toast med meddelande från backend
        setError('');
      } else {
        const errorData = await response.json();
        console.error('Failed to update ticket:', errorData);
        toast.error(errorData.error || 'Kunde inte uppdatera ärendet'); // Visa fel-toast
        setError(errorData.error || 'Kunde inte uppdatera ärendet');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Error updating ticket:', error);
      toast.error('Kunde inte uppdatera ärendet'); // Visa fel-toast
      setError('Kunde inte uppdatera ärendet');
      setSuccessMessage('');
    }
  };

    // Function to handle print styles
    const pageStyle = `
    @page {
      size: auto;
      margin: 20mm;
    }
    @media print {
      body {
        -webkit-print-color-adjust: exact;
      }
      /* Additional print styles */
    }
  `;

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    pageStyle: pageStyle,
  });

  const handleStatusChange = async (newStatus, sendEmail) => {
    handleFormDataChange({ status: newStatus, sendEmail });
  };

  useEffect(() => {
    const fetchTicketAndCustomer = async () => {
      try {
        const ticketResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/tickets/${id}`);
        const ticketData = await ticketResponse.json();
        setTickets([ticketData]);

        const customerResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/customers/${ticketData.customer_id}`);
        const customerData = await customerResponse.json();
        setCustomer(customerData.customer);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    if (!tickets || tickets.length === 0) {
      fetchTicketAndCustomer();
    } else {
      const activeTicket = tickets.find(ticket => ticket.ticket_id.toString() === id);
      if (activeTicket) {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/customers/${activeTicket.customer_id}`)
          .then(response => response.json())
          .then(data => setCustomer(data.customer))
          .catch(error => console.error('Error fetching customer:', error));
      }
      setLoading(false);
    }
  }, [id, setTickets]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const activeTicket = tickets.find(ticket => ticket.ticket_id.toString() === id);

  if (!activeTicket) {
    return <div>Ticket not found</div>;
  }

  return (
    <>
      <PrintStyles />
      <ToastContainer /> {/* Lägg till ToastContainer */}
      <ArendenContainer>
        <Title>Ärenden</Title>
        <TicketPageContainer>
          <StatusContainer>
            <Andrastatus currentStatus={activeTicket.status} onStatusChange={handleStatusChange} />
          </StatusContainer>

          {/* Print Button */}
          <PrintButton onClick={handlePrint} className="no-print">Skriv ut</PrintButton>

          {/* Content to Print */}
          <PrintableContent
            ref={componentRef}
            activeTicket={activeTicket}
            customer={customer}
            onFormDataChange={handleFormDataChange}
          />

        </TicketPageContainer>
      </ArendenContainer>
    </>
  );
};

// Styling för meddelanden
const SuccessMessage = styled.p`
  color: green;
  font-size: 1rem;
  text-align: center;
  margin-top: 20px;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 1rem;
  text-align: center;
  margin-top: 20px;
`;

export default TicketPage;



