// src/components/Layout.js
import React, { useState } from 'react';
import styled from 'styled-components';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import { Outlet } from 'react-router-dom';
import { device } from '../styles/breakpoints'; // Importera breakpoints

const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const MainContent = styled.div`
  flex-grow: 1;
  background: ${({ theme }) => theme.colors.background};
  padding: 20px;
  padding-top: 72.72px; /* Justera efter Topbar höjd */
  margin-left: 241.5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;

  @media ${device.tablet} {
    padding-top: 60px; /* Justera padding för mobila enheter */
    margin-left: 0;
  }
`;

const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <LayoutContainer>
      <Sidebar isOpen={isSidebarOpen} />
      <Topbar toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
      <MainContent>
        <Outlet /> {/* Renders the matched child route */}
      </MainContent>
    </LayoutContainer>
  );
};

export default Layout;


