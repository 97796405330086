import React from 'react';
import styled from 'styled-components';

const DeleteButton = styled.button`
  background-color: #ff4d4d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: #e04a4a;
  }
`;

const RaderaKundKnapp = ({ customerId }) => {
  const handleDelete = async () => {
    const confirmDelete = window.confirm("Är du säker på att du vill ta bort kunden och alla relaterade ärenden?");
    if (!confirmDelete) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/customers/${customerId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Kunde inte ta bort kunden');
      }

      const data = await response.json();
      //console.log(data.message); // Log success message
      alert("Kunden & alla relaterade ärenden har tagits bort");
      // Optionally, you can redirect the user to another page after deletion
      window.location.href = '/kunder';
    } catch (error) {
      console.error('Error deleting customer:', error);
      alert('Ett fel uppstod när kunden skulle tas bort.');
    }
  };

  return <DeleteButton onClick={handleDelete}>Radera Kund</DeleteButton>;
};

export default RaderaKundKnapp;
