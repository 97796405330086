import React, { createContext, useState, useContext, useEffect, useCallback, useMemo } from 'react';

const TicketContext = createContext();

export const useTickets = () => {
  return useContext(TicketContext);
};

export const TicketProvider = ({ children, customerId = null }) => {
  const [tickets, setTickets] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  

  // Define the order for ticket statuses
  const statusOrder = {
    'Ska göras': 1,
    'Väntar på sulmått': 2,
    'Väntar på delar': 3,
    'Väntar på produkt': 4,
    'Färdigt': 5,
  };

  // Memoize the sortTicketsByStatus function to prevent infinite re-renders
  const sortTicketsByStatus = useCallback((tickets) => {
    return tickets.sort((a, b) => {
      const orderA = statusOrder[a.status] || 99; // Default to a high number if status is unrecognized
      const orderB = statusOrder[b.status] || 99;
      return orderA - orderB;
    });
  }, []); // No dependencies because statusOrder doesn't change

  // Fetch tickets from the database when the component mounts
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        let url = `${process.env.REACT_APP_API_BASE_URL}/api/tickets`; // Default to fetch all tickets
        //console.log('Fetching tickets from:', url);

        // If customerId is provided, fetch tickets specific to that customer
        if (customerId) {
          url += `?customer_id=${customerId}`;
        }

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Failed to fetch tickets');
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          const data = await response.json();
          const processedTickets = data.map(ticket => ({
            ...ticket,
            product: ticket.product || ticket.skida || ticket.pjaxa,
          }));
          const sortedTickets = sortTicketsByStatus(processedTickets);
          setTickets(sortedTickets);
        } else {
          throw new Error('Expected JSON but received something else');
        }
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };

    fetchTickets();
  }, [customerId, sortTicketsByStatus]);

  // Function to add a new ticket
  const addTicket = async (newTicket) => {
    try {
      // Ensure the product field is set correctly before sending the ticket to the backend
      const ticketToAdd = {
        ...newTicket,
        product: newTicket.product || newTicket.skida || newTicket.pjaxa,
      };

      const response = await fetch('/api/tickets', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ticketToAdd),
      });

      const createdTicket = await response.json();

      // Apply the same processing logic to the newly created ticket
      const processedTicket = {
        ...createdTicket,
        product: createdTicket.skida || createdTicket.pjaxa || createdTicket.product,
      };

      setTickets((prevTickets) => {
        const updatedTickets = [...prevTickets, processedTicket];
        return sortTicketsByStatus(updatedTickets);
      });
    } catch (error) {
      console.error('Error creating ticket:', error);
    }
  };

  const filteredTickets = useMemo(() => {
    if (!searchQuery.trim()) {
      return tickets;
    }
  
    const lowerCaseQuery = searchQuery.toLowerCase();
  
    return tickets.filter(ticket => {
      const ticketIdMatch = ticket.ticket_id.toString().toLowerCase().includes(lowerCaseQuery);
      const customerNameMatch = (ticket.customer_name || '').toLowerCase().includes(lowerCaseQuery);
      return ticketIdMatch || customerNameMatch;
    });
  }, [tickets, searchQuery]);

  {/*console.log('TicketContext Provider Value:', { 
    tickets: filteredTickets, 
    setTickets, 
    addTicket, 
    sortTicketsByStatus,
    searchQuery,
    setSearchQuery,
    isLoading,
    error 
  });*/}

  return (
    <TicketContext.Provider value={{ 
      tickets: filteredTickets, 
      setTickets, 
      addTicket, 
      sortTicketsByStatus,
      searchQuery,
      setSearchQuery,
      isLoading,
      error
    }}>
      {children}
    </TicketContext.Provider>
  );
};

export { TicketContext };

